import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { pink, cyan } from '@mui/material/colors';
import { SITE_BANNER } from './services/config';

const theme = createTheme({
  palette: {
    primary: {
      main: pink[700],
    },
    secondary: {
      main: cyan[900],
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: ({ ownerState }) => ({
          ...(ownerState.readOnly ? { cursor: 'default' } : {}),
        }),
        root: ({ ownerState }) => ({
          ...(ownerState.readOnly ? { cursor: 'default' } : {}),
        }),
      },
    },
  },
});

document.title = SITE_BANNER;

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
