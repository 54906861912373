import { ChildInfoResponse } from "./api";
import { Gender } from "@shared/types";

export enum actions {
  saveDonorInfo = "SAVE_DONOR_INFO",
  saveDonationInfo = "SAVE_DONATION_INFO",
  saveAdminSecret = "SAVE_ADMIN_SECRET",
}

export type AppState = {
  donorInfo: {
    name: string;
    email: string;
    phone: string;
  };
  donation: {
    id: string;
    child: ChildInfoResponse;
  };
  adminSecret: string;
};
export const initialAppState: AppState = {
  donorInfo: { name: "", email: "", phone: "" },
  donation: null,
  adminSecret: "",
};

export function appReducer(state: AppState, { type, payload }): AppState {
  switch (type) {
    case actions.saveDonorInfo:
      return {
        ...state,
        donorInfo: payload,
      };
    case actions.saveDonationInfo:
      return {
        ...state,
        donation: payload,
      };
    case actions.saveAdminSecret:
      return {
        ...state,
        adminSecret: payload,
      };

    default:
      throw new Error("invalid action dispatched");
  }
}
