import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { INSTRUCTIONS, INSTRUCTIONS_ESP, SHOW_SPANISH, SITE_BANNER } from "../services/config";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const InstructionModal = ({ isModalOpen, setModalOpen }) => {
  const [language, setLanguage] = useState("eng");
  const handleCloseModal = () => setModalOpen(false);

  return (
    <Dialog
      aria-labelledby="instructions popup"
      onClose={handleCloseModal}
      open={isModalOpen}
      scroll="paper"
    >
      <DialogTitle>
        <TitleBar>
          <p>Welcome to {SITE_BANNER}</p>
          {SHOW_SPANISH && (
            <ToggleButtonGroup
              color="primary"
              size="small"
              sx={{
                display: "flex",
              }}
              value={language}
              exclusive
              onChange={(e, value) => setLanguage(value)}
              aria-label="Language"
            >
              <ToggleButton value="esp">Español</ToggleButton>
              <ToggleButton value="eng">English</ToggleButton>
            </ToggleButtonGroup>
          )}
        </TitleBar>
      </DialogTitle>
      <StyledContent dividers>
        <DialogContentText
          tabIndex={-1}
          dangerouslySetInnerHTML={{ __html: language === "eng" ? INSTRUCTIONS : INSTRUCTIONS_ESP }}
        ></DialogContentText>
      </StyledContent>
      <ActionArea>
        <Button variant="text" color="primary" onClick={() => setModalOpen(false)}>
          ok
        </Button>
      </ActionArea>
    </Dialog>
  );
};
const StyledContent = styled(DialogContent)`
  white-space: pre-wrap;
`;

const TitleBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  p {
    text-wrap: nowrap;
  }
`;

const ActionArea = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;
export default InstructionModal;
