import React from "react";
import styled from "styled-components";
type SnowLayer = "front" | "mid" | "back";
type SnowLayerSpecs<T extends number | string> = { [K in SnowLayer]: T };
type SnowConfig = {
  size: string;
  blur: string;
  duration: number;
  iterationCount: string | number;
  boxShadow: string;
};

// reference https://youtu.be/8eyAoBBucHk
const flakCount: SnowLayerSpecs<number> = { front: 10, mid: 20, back: 30 };
const zIndices: SnowLayerSpecs<number> = { front: 55, mid: 45, back: 35 };
const sizes: SnowLayerSpecs<string> = { front: "2.5vh", mid: "2vh", back: "1.5vh" };
const blurLevels: SnowLayerSpecs<string> = { front: "1.5px", mid: "3px", back: "6px" };
const speeds: SnowLayerSpecs<number> = { front: 12, mid: 16, back: 20 };
const iterationCount: SnowLayerSpecs<number | string> = { front: 1, mid: 2, back: "infinite" };
const Snow = ({ layer }: { layer: SnowLayer }) => {
  const boxShadow = getBoxShadow(flakCount[layer]);
  const config: SnowConfig = {
    size: sizes[layer],
    blur: blurLevels[layer],
    duration: speeds[layer],
    iterationCount: iterationCount[layer],
    boxShadow,
  };

  return (
    <Container $zIndex={zIndices[layer]}>
      <Flakes $config={config} />
      <Flakes $config={config} $delayed />
    </Container>
  );
};

const Container = styled.ul<{ $zIndex: string | number }>`
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.$zIndex};

  @keyframes fall {
    100% {
      transform: translateY(200vh);
    }
  }
`;
const Flakes = styled.li<{ $config: SnowConfig; $delayed?: boolean }>`
  --size: ${props => props.$config.size};
  border-radius: 50%;
  opacity: 0.8;
  width: var(--size);
  height: var(--size);
  filter: blur(${props => props.$config.blur});

  box-shadow: ${props => props.$config.boxShadow};
  animation: fall linear ${props => props.$config.iterationCount};
  animation-duration: ${props => props.$config.duration}s;
  animation-delay: ${props => (props.$delayed ? props.$config.duration / 2 : 0)}s;
`;

function getBoxShadow(flakeCount) {
  let result = "";
  for (let i = 0; i < flakeCount; i++) {
    result = `${result}${Math.floor(Math.random() * 1000) / 10}vw ${
      Math.floor(Math.random() * 900) / 10
    }vh 0 -${Math.floor(Math.random() * 5) / 10}rem white`;
    i !== flakeCount - 1 && (result += ",");
  }
  return result;
}

export default Snow;
