import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const dayjsExtended = dayjs;

export const itemsToArray = (str: string): string[] => {
  return str
    .trim()
    .split(",")
    .map(item => item.trim())
    .filter(item => item.length > 1);
};
export const getRandomItem = collection =>
  collection[Math.floor(Math.random() * collection.length)];
export const getRandomItems = (collection, count) => {
  const usedIndices = {};
  const result = [];
  for (let i = 0; i < count; i++) {
    const pick = Math.floor(Math.random() * collection.length);
    if (!usedIndices[pick]) {
      usedIndices[pick] = true;
      result.push(collection[pick]);
    } else {
      i--;
    }
  }
  return result;
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface FormData {
  email?: string;
  phone?: string;
  name?: string;
}
export const validateForm = (data: FormData): FormData => {
  const { email, phone, name } = data;
  let errors: FormData = {};
  name !== undefined && (errors = validateName(name, errors));
  email !== undefined && (errors = validateEmail(email, errors));
  phone !== undefined && (errors = validatePhone(phone, errors));
  return errors;
};
function validateName(name, prevErrors) {
  if (name.trim() === "") {
    return { ...prevErrors, name: "Name is required" };
  }
  if (name.trim().length < 3) {
    return { ...prevErrors, name: "Please use your full name" };
  }
  return prevErrors;
}
function validateEmail(email, prevErrors) {
  if (email.trim() === "") {
    return { ...prevErrors, email: "Email is required" };
  }
  if (!emailRegex.test(email)) {
    return { ...prevErrors, email: "Invalid email address" };
  }
  return prevErrors;
}

function validatePhone(phone, prevErrors) {
  if (phone.trim() === "") {
    return { ...prevErrors, phone: "Phone number is required" };
  }
  if (phone.trim().length < 10) {
    return { ...prevErrors, phone: "Please enter a valid number" };
  }
  return prevErrors;
}
