import React, { useMemo } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { ChildInfoResponse } from "../services/api";
import { itemsToArray } from "../services/utils";
import { SEPARATE_TOYS_APPAREL } from "../services/config";

const ChildInfo = ({
  childInfo,
  isLoading = false,
}: {
  childInfo: ChildInfoResponse;
  isLoading?: boolean;
}) => {
  const { toyWishes, apparelWishes } = childInfo ?? {};
  const toys: string[] = useMemo(() => {
    if (toyWishes?.items) {
      return itemsToArray(toyWishes.items);
    }
    return [];
  }, [childInfo]);

  const apparels: string[] = useMemo(() => {
    if (apparelWishes?.items) {
      return itemsToArray(apparelWishes.items);
    }
    return [];
  }, [childInfo]);

  const isApparels = !!apparels.length;
  const items = isApparels ? apparels : toys;

  return (
    <Container>
      {SEPARATE_TOYS_APPAREL ? (
        <Card variant="outlined">
          <CardContent>
            <Typography color="textSecondary">Wish List {isApparels && "(sizes)"}</Typography>
            <List component="ul" aria-label="child's wish list">
              {isLoading ? (
                <ListSkeleton />
              ) : (
                items.map((item, index) => (
                  <ListItem key={item + index}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
      ) : (
        <>
          <Card variant="outlined">
            <CardContent>
              <Typography color="textSecondary">Wishes</Typography>
              <List component="ul" aria-label="child's wish list">
                {isLoading ? (
                  <ListSkeleton />
                ) : (
                  toys.map(item => (
                    <ListItem key={item}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))
                )}
              </List>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography color="textSecondary">Sizes</Typography>
              <List component="ul" aria-label="child's wish list">
                {isLoading ? (
                  <ListSkeleton />
                ) : apparels.length ? (
                  apparels.map(item => (
                    <ListItem key={item}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2">No Sizing Info</Typography>
                )}
              </List>
            </CardContent>
          </Card>
        </>
      )}
    </Container>
  );
};
const Container = styled.article`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  & > * {
    flex: 1 1 10rem;
  }
`;

const SkeletonContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    flex-basis: 24px;
    margin: 8px 16px;
  }
`;
const ListSkeleton = () => (
  <SkeletonContainer>
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </SkeletonContainer>
);
export default ChildInfo;
